import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Tilastot = ({ location: { pathname } }) => (
  <Layout pathname={pathname}>
    <SEO title="Suomen Hautaustoiminnan keskusliitto Ry - Tilastot" />
    <div className="hero-area full-container">
      <div className="page-banner parallax">
        <div className="container">
          <h1>Tilastot</h1>
        </div>
      </div>
    </div>

    <div className="col-md-8 col-md-offset-2">
      <h2>
        Hautaustoimen tilastot löytyvät nykyisin{" "}
        <a
          href="https://www.kirkontilastot.fi"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.kirkontilastot.fi
        </a>
      </h2>
      <p>Kirkon tilastopalvelun käyttöohje:</p>
      <p>
        Kirkon tilastopalvelun internet-osoite on{" "}
        <a
          href="https://www.kirkontilastot.fi"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.kirkontilastot.fi
        </a>
        .
      </p>{" "}
      Tilastot on jaettu neljään ryhmään: jäsentilastot, taloustilastot,
      toimintatilastot ja muut tilastot. Muut tilastot kohdasta löytyy
      hautaustoimen tilastot. Kunkin ryhmän alla raportit on vielä ryhmitelty
      tilastovuoden mukaan. Myös tilastopalvelun etusivulla on linkki
      tuoreimpiin tilastoraportteihin.
      <p />
      <p />
      <h2>Krematoriotoiminnan tilastoja</h2>
      <p>
        <a href="/doc/tilastot/tilasto_krematoriot_2023.xls">
          Tilasto krematoriot 2023
        </a>
      </p>
      <p>
        <a href="/doc/tilastot/tuhkaus_2013-2023.xlsx">
          Tuhkausten prosentuaalinen määrä 2013-2023
        </a>
      </p>
      <p>
        <a href="/doc/tilastot/tilasto_krematoriot_2022.xls">
          Tilasto krematoriot 2022
        </a>
      </p>
      <p>
        <p><a href="/doc/tilastot/tilasto-krematoriot-2021.xls">Tilasto krematoriot 2021</a></p>
        <a href="/doc/tuhkaus_2009-2020.xls">
          Tuhkausten prosentuaalinen määrä 2009-2020
        </a>
      </p>
      <p>
        <a href="/doc/tilasto_krematoriot_2020.xls">Tilasto krematoriot 2020</a>
      </p>
      <p>
        <a href="/doc/tilastokuva_2019.docx">Tilastokuva 2019</a>
      </p>
      <p>
        <a href="/doc/tilasto_krematoriot_2019.xlsx">
          Tilasto krematoriot 2019
        </a>
      </p>
      <p>
        <a href="/doc/tilasto_krematoriot_2018.xls">Tilasto krematoriot 2018</a>
      </p>
      <p>
        <a href="/doc/Tilasto_krematoriot_2016.xls">Tilasto krematoriot 2016</a>
      </p>
      <p>
        <a href="/doc/Tilasto_-krematoriot_2015_julkaise.xls">
          Tilasto krematoriot 2015
        </a>
      </p>
      <p>
        <a href="/doc/Tilasto-krematoriot-2014.xls">Tilasto krematoriot 2014</a>
      </p>
      <p>
        <a href="/doc/Tilasto+krematoriot+2013.pdf">
          Krematoriotoiminnan tilastot vuosilta 2008, 2009, 2010, 2011, 2012,
          2013
        </a>
      </p>
      <p>
        <a href="/doc/krematoriotilastot2004-2007.pdf">
          Krematoriotoiminnan tilastot vuosilta 2004,2005,2006 ja 2007
        </a>
      </p>
      <p>
        <a href="/doc/tuhkaustilasto.pdf">
          Pitkäaikainen tilasto tuhkauksista Suomessa
        </a>
      </p>
      <a href="/doc/muutmaat.pdf">
        Tuhkahautauksen kehitys muissa maissa 1995-2003
      </a>
      <p />
      <h2>Hautaustoiminnan tilastoja</h2>
      <p>
        <a href="/doc/Tilasto_2016_seurakunnat_yli_200_hautausta_vuodessa.xls">
          Tilasto 2016 seurakunnat yli 200 hautausta vuodessa
        </a>
      </p>
      <p>
        <a href="/doc/Tilasto_2016_seurakunnat_alle_200_hautausta_vuodessa.xls">
          Tilasto 2016 seurakunnat alle 200 hautausta vuodessa
        </a>
      </p>
      <p>
        <a href="/doc/Tilasto-2014-seurakunnat-alle-200-hautausta-vuodessa.xls">
          Tilasto 2014 seurakunnat alle 200 hautausta vuodessa
        </a>
      </p>
      <p>
        <a href="/doc/Tilasto_2014_seurakunnat_yli_200_hautausta_vuodessa.xls">
          Tilasto 2014 seurakunnat yli 200 hautausta vuodessa
        </a>
      </p>
      <p>
        <a href="/doc/Tilasto_2013_seurakunnat_yli_200_hautausta_vuodessa.pdf">
          Tilasto 2013 yli 200 hautausta vuodessa
        </a>
      </p>
      <p>
        <a href="/doc/Tilasto+2013+seurakunnat+alle+200+hautausta+vuodessa.pdf">
          Tilasto vuosilta 2011, 2012 ja 2013 (alle 200 hautausta vuodessa)
        </a>
      </p>
      <p>
        <a href="/doc/2008_2009_2010_alle200.pdf">
          Tilasto vuosilta 2008, 2009 ja 2010 (alle 200 hautausta vuodessa)
        </a>
      </p>
      <p>
        <a href="/doc/2008_2009_2010_yli200.pdf">
          Tilasto vuosilta 2008, 2009 ja 2010 (yli 200 hautausta vuodessa)
        </a>
      </p>
      <p>
        <a href="/doc/2005_2007_alle200.pdf">
          Tilastoja vuosilta 2005, 2006 ja 2007 (alle 200 hautausta vuodessa)
        </a>
      </p>
      <p>
        <a href="/doc/2005_2007_yli200.pdf">
          Tilastoja vuosilta 2005, 2006 ja 2007 (yli 200 hautausta vuodessa)
        </a>
      </p>
    </div>
  </Layout>
)

export default Tilastot
